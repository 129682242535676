import { useMutation, useQueryClient } from '@tanstack/react-query';

import { calendarApi } from '../api';
import { debounce } from 'lodash';

interface UseSyncCalendarEventsParams {
  calendarIds: string[];
  userId: string;
  startTime: string;
  endTime: string;
}

export function useSyncCalendarEvents() {
  const queryClient = useQueryClient();

  const syncCalMutation = useMutation({
    mutationFn: ({ calendarIds, userId, startTime, endTime }: UseSyncCalendarEventsParams) => {
      return calendarApi.syncCalendarEvents(userId, startTime, endTime, calendarIds);
    },
    onSuccess: debounce(() => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.eventsForPhase'] });
    }, 2000),
  });

  return {
    syncCalEvents: syncCalMutation.mutateAsync,
    ...syncCalMutation,
  };
}
