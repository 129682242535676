import { Calendar, CalendarEvent } from '@demind-inc/core';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { chunk, union } from 'lodash';
import { useRecoilValue } from 'recoil';

import {
  selectedDateAtom,
  useAuthContext,
  useCalendarEvents,
  useCalendarsMeta,
  useSyncCalendarEvents,
  useUpdateCalendarInfo,
} from '../../data-access';
import { getUTCStartEndTimeOfDay, getWeekEdgesDateOf } from '../helpers';

interface ICalendarContext {
  calendarsMeta: Calendar[];
  visibleCalendarIds: string[];
  mainCalendar?: Calendar;
  calendarAccountsToCreateEvents: Calendar[];
  calendarEvents: CalendarEvent[];
  isFetchingCalendars: boolean;
  isSyncingEvents: boolean;
  reSyncCalEvents: (calendarIds: string[]) => Promise<void>;
  findCalendarItem: (targetCalendarId: string) => Calendar | undefined;
  checkCalendarHassAccessToModifyEvent: (targetCalendarId: string) => boolean;
  handleSelectCalendar: (targetCalendar: Calendar, checked: boolean) => void;
}

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const SYNC_CALENDAR_CHUNK_SIZE = 5;
export const CalendarContext = createContext({} as ICalendarContext);
export const useCalendarContext = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }: { children: ReactNode }) => {
  const selectedDate = useRecoilValue(selectedDateAtom);

  const [syncedWeeks, setSyncedWeeks] = useState<string[]>([]);
  const [visibleCalendarIds, setVisibleCalendarIds] = useState<string[]>([]);
  const selectedStartEndTime = useMemo(() => getUTCStartEndTimeOfDay(selectedDate), [selectedDate]);

  const { startDateOfWeek, endDateOfWeek } = useMemo(() => {
    const { startDate, endDate } = getWeekEdgesDateOf(selectedDate);
    return { startDateOfWeek: startDate, endDateOfWeek: endDate };
  }, [selectedDate]);

  const { user } = useAuthContext();
  const { calendarsMeta, isLoading: isFetchingCalendars } = useCalendarsMeta({
    userId: user.userId,
  });
  const { events } = useCalendarEvents({
    userId: user.userId,
    startDate: selectedStartEndTime.startTime.toISOString(),
    endDate: selectedStartEndTime.endTime.toISOString(),
  });
  const { syncCalEvents, isPending: isSyncingEvents } = useSyncCalendarEvents();
  const { updateCalendarInfo } = useUpdateCalendarInfo();

  const mainCalendar = useMemo(() => {
    const mainCalendar = calendarsMeta.find((info) =>
      info.default === undefined ? info?.internalGCalendarId === user.email : info.default
    );

    return mainCalendar;
  }, [calendarsMeta, user.email]);

  const calendarAccountsToCreateEvents = useMemo(
    () => calendarsMeta.filter(({ scope }) => !['freeBusyReader', 'reader'].includes(scope!)),
    [calendarsMeta]
  );

  const filteredEvents = useMemo(
    () =>
      events.filter(
        (event) => visibleCalendarIds.includes(event.calendarId!) && event.status !== 'cancelled'
      ),
    [visibleCalendarIds, events]
  );

  // Sync the events from the Google/Outlook Calendar for a week
  useEffect(() => {
    if (syncedWeeks.includes(startDateOfWeek.toISOString()) || !user.calendarIds?.length) {
      return;
    }

    // Chunk the calendar ids to avoid long response time
    chunk(user.calendarIds, SYNC_CALENDAR_CHUNK_SIZE).forEach((calendarIds) => {
      syncCalEvents({
        userId: user.userId,
        calendarIds: calendarIds,
        startTime: startDateOfWeek.toISOString(),
        endTime: endDateOfWeek.toISOString(),
      });
    });
    setSyncedWeeks((prev) => union(prev, [startDateOfWeek.toISOString()]));
  }, [startDateOfWeek.toISOString(), user.calendarIds]);

  // Update the visible calendar ids when the calendar meta is fetched
  useEffect(() => {
    if (!calendarsMeta.length) {
      return;
    }

    setVisibleCalendarIds(
      calendarsMeta
        .filter((info) => !!info && info.visible)
        .map((info) => info?.calendarId ?? [])
        .flat()
    );
  }, [calendarsMeta.length]);

  const reSyncCalEvents = useCallback(
    async (calendarIds: string[]) => {
      await syncCalEvents({
        userId: user.userId,
        calendarIds,
        startTime: startDateOfWeek.toISOString(),
        endTime: endDateOfWeek.toISOString(),
      });
      setSyncedWeeks((prev) => union(prev, [startDateOfWeek.toISOString()]));
    },

    [user, startDateOfWeek.toISOString()]
  );

  const findCalendarItem = useCallback(
    (targetCalendarId: string) => {
      return calendarsMeta.find(({ calendarId }) => calendarId === targetCalendarId);
    },
    [calendarsMeta]
  );

  const checkCalendarHassAccessToModifyEvent = useCallback(
    (targetCalendarId: string) => {
      const targetCalendar = findCalendarItem(targetCalendarId);

      return !['freeBusyReader', 'reader'].includes(targetCalendar?.scope!);
    },
    [findCalendarItem]
  );

  const handleSelectCalendar = useCallback((targetCalendar: Calendar, checked: boolean) => {
    if (checked) {
      setVisibleCalendarIds((prev) => [...prev, targetCalendar.calendarId]);
    } else {
      setVisibleCalendarIds((prev) => prev.filter((id) => id !== targetCalendar.calendarId));
    }
    updateCalendarInfo({
      calendarId: targetCalendar.calendarId,
      newCalendarInfo: { visible: checked },
    });
  }, []);

  return (
    <CalendarContext.Provider
      value={{
        mainCalendar,
        visibleCalendarIds,
        calendarsMeta,
        calendarAccountsToCreateEvents,
        calendarEvents: filteredEvents,
        isFetchingCalendars,
        isSyncingEvents,
        reSyncCalEvents,
        findCalendarItem,
        handleSelectCalendar,
        checkCalendarHassAccessToModifyEvent,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};
