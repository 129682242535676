import React, { useState } from 'react';
import clsx from 'clsx';
import { DateCalendar } from '@mui/x-date-pickers';
import { useRecoilState } from 'recoil';

import './SideBar.scss';
import LifestackLogoName from '../../../assets/logo/logo_with_name.png';
import {
  CalendarsList,
  SettingsBlock,
  GeneralCTA,
  SettingsModal,
  TodoList,
} from '../../../components';
import { selectedDateAtom, useCalendarContext } from '../../../data-access';
import { useConnectCalendar } from '../../../hooks';

interface SideBarProps {
  className?: string;
}

const SideBar: React.FC<SideBarProps> = ({ className }) => {
  const { calendarsMeta, visibleCalendarIds, isFetchingCalendars, handleSelectCalendar } =
    useCalendarContext();
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateAtom);

  const { connectCal } = useConnectCalendar();

  return (
    <div className={clsx('sidebar', className)}>
      <div className="sidebar__header">
        <img src={LifestackLogoName} alt="Lifestack Logo" className="sidebar__header__logo" />
      </div>
      <div className="sidebar__content">
        <div className="sidebar__calendar">
          <DateCalendar
            value={selectedDate}
            onChange={(val) => setSelectedDate(val)}
            className="sidebar__calendar__content"
          />
        </div>
        <div className="sidebar__selector-group">
          <CalendarsList
            calendarsMeta={calendarsMeta}
            visibleCalendarIds={visibleCalendarIds}
            isFetchingCalendars={isFetchingCalendars}
            onSelectCalendar={handleSelectCalendar}
            onConnectCalendar={connectCal}
            className="sidebar__calendars"
          />
          <TodoList className="sidebar__todos" />
          <GeneralCTA className="sidebar__general-cta" />
        </div>
      </div>
      <SettingsBlock
        className="sidebar__settings"
        onClickSetting={() => setSettingsModalVisible(true)}
      />
      <SettingsModal
        visible={settingsModalVisible}
        onClose={() => setSettingsModalVisible(false)}
      />
    </div>
  );
};

export default SideBar;
