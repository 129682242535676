import { FC, useMemo, useState } from 'react';
import { Button, Modal, TextField } from '@mui/material';
import { TaskItem } from '@demind-inc/core';
import { CloseOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';

import './TaskDetailModal.scss';
import {
  circadianPhaseInfo,
  eventsSnackBarAtom,
  useAuthContext,
  useCircadianContext,
  useTodoTasksContext,
  useUpdateTodoTask,
} from '../../data-access';
import { getCssVariable } from '../../utils';
import { useRecoilState } from 'recoil';
import { TaskDueDatePicker } from './TaskDueDatePicker';
import { getFormattedDueDateForUpdateTask, getFormattedDuration } from '../../helpers';

interface TaskDetailModalProps {
  visible: boolean;
  task: TaskItem;
  onClose: () => void;
}
export type TaskDetail = Pick<TaskItem, 'name' | 'desc' | 'dueDateTime' | 'startDateTime'>;

const labelCallbackColor = getCssVariable('--color-gray-light');

const TaskDetailModal: FC<TaskDetailModalProps> = ({ visible, task: defaultTask, onClose }) => {
  const { user } = useAuthContext();
  const { findProject } = useTodoTasksContext();
  const { findPhaseForTaskTime } = useCircadianContext();
  if (!defaultTask) {
    return null;
  }
  const [taskInfo, setTaskInfo] = useState<TaskDetail>({
    name: defaultTask?.name || '',
    desc: defaultTask?.desc || '',
    dueDateTime: {
      datetime: defaultTask?.dueDateTime?.datetime,
      date: defaultTask?.dueDateTime?.date,
      timezone: defaultTask?.dueDateTime?.timezone || dayjs.tz.guess(),
    },
    startDateTime: {
      datetime: defaultTask?.startDateTime?.datetime,
      date: defaultTask?.startDateTime?.date,
      timezone: defaultTask?.startDateTime?.timezone || dayjs.tz.guess(),
    },
  });

  const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);
  const { updateTodoTask, isUpdating } = useUpdateTodoTask();

  const canSave = useMemo(() => {
    const { name, desc, dueDateTime } = taskInfo;

    const nameChanged = name !== defaultTask?.name;
    const descChanged = desc !== defaultTask?.desc;
    const nameNotEmpty = name?.trim() !== '';
    const dueDateChanged =
      dueDateTime.date !== defaultTask.dueDateTime?.date ||
      dueDateTime.datetime !== defaultTask.dueDateTime?.datetime;
    const startDateChangeds =
      taskInfo.startDateTime?.date !== defaultTask.startDateTime?.date ||
      taskInfo.startDateTime?.datetime !== defaultTask.startDateTime?.datetime;

    return ((nameChanged || descChanged) && nameNotEmpty) || dueDateChanged || startDateChangeds;
  }, [defaultTask, taskInfo]);

  const handleUpdateTask = async () => {
    const { name, desc, dueDateTime, startDateTime } = taskInfo;
    try {
      onClose();

      await updateTodoTask({
        userId: user?.userId,
        boardId: defaultTask.boardId,
        taskId: defaultTask.taskId,
        newTaskInfo: {
          name,
          desc,
          ...getFormattedDueDateForUpdateTask('due', dueDateTime),
          ...getFormattedDueDateForUpdateTask('start', startDateTime),
          ...getFormattedDuration(startDateTime, dueDateTime),
          appFrom: defaultTask.appFrom,
        },
      });
      setEventsSnackbar('Updated task successfully!');
    } catch (error) {
      console.error('Failed to update task:', error);
    }
  };

  const taskTime = useMemo(() => {
    const startTime = defaultTask.startDateTime?.datetime
      ? dayjs(defaultTask.startDateTime.datetime).format('HH:mm')
      : '';
    const endTime = defaultTask.dueDateTime?.datetime
      ? dayjs(defaultTask.dueDateTime.datetime).format('HH:mm')
      : '';

    if (!startTime || !endTime) {
      return;
    }

    return `${startTime} - ${endTime}`;
  }, [defaultTask]);

  const phase = useMemo(() => {
    if (!defaultTask.startDateTime?.datetime || !defaultTask.dueDateTime?.datetime) {
      return;
    }

    const targetPhase = findPhaseForTaskTime(
      defaultTask.startDateTime.datetime,
      defaultTask.dueDateTime.datetime
    );
    if (!targetPhase) {
      return;
    }
    return circadianPhaseInfo[targetPhase];
  }, [defaultTask]);

  return (
    <Modal open={visible} onClose={onClose} className="task-detail-modal">
      <div className="task-detail-modal__container">
        <div className="task-detail-modal__header">
          <div className="task-detail-modal__header__title">Task</div>
          <div className="task-detail-modal__header__right-group">
            <Button
              disabled={!canSave || isUpdating}
              onClick={handleUpdateTask}
              variant="contained"
            >
              Save
            </Button>
            <CloseOutlined
              fontSize="small"
              className="task-detail-modal__header__close"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="task-detail-modal__content">
          <div className="task-detail-modal__content__top-group">
            <div className="task-detail-modal__project-group">
              <div className="task-detail-modal__project">
                # {findProject(defaultTask.boardId!)?.name}
              </div>
            </div>
            <div className="task-detail-modal__time-group">
              {!!taskTime && (
                <div
                  className="task-detail-modal__time-group__time"
                  style={{ borderColor: phase?.color, color: phase?.color }}
                >
                  {taskTime}
                </div>
              )}
              {phase?.label && (
                <div
                  className="task-detail-modal__time-group__phase"
                  style={{ backgroundColor: phase?.color }}
                >
                  {phase.label}
                </div>
              )}
            </div>
          </div>
          <div className="task-detail-modal__title-group">
            <TextField
              label="Title"
              value={taskInfo.name}
              onChange={(e) => setTaskInfo((prev) => ({ ...prev, name: e.target.value }))}
              variant="standard"
              className="task-detail-modal__title-input"
            />
            <TextField
              label="Description"
              multiline
              rows={2}
              value={taskInfo.desc}
              onChange={(e) => setTaskInfo((prev) => ({ ...prev, desc: e.target.value }))}
              variant="standard"
              className="task-detail-modal__desc-textarea"
            />
          </div>
          <div className="task-detail-modal__details-section">
            {!!defaultTask.labels?.length && (
              <div className="task-detail-modal__labels">
                <div className="task-detail-modal__labels__title">Labels</div>
                <div className="task-detail-modal__labels__items">
                  {defaultTask.labels?.map((label) => (
                    <div className="task-detail-modal__labels__items__item" id={label.name}>
                      <div
                        className="task-detail-modal__labels__items__item__color"
                        style={{ backgroundColor: label.color || labelCallbackColor }}
                      />
                      <div className="task-detail-modal__labels__items__item__name">
                        {label.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="task-detail-modal__due-date-picker">
              {/* Show Start Date picker only when due datetime is set  */}
              {taskInfo.dueDateTime.datetime && (
                <TaskDueDatePicker
                  label="Due Date (Start)"
                  maxDateTime={
                    taskInfo.dueDateTime.datetime ? dayjs(taskInfo.dueDateTime.datetime) : undefined
                  }
                  dateSet={{
                    date: taskInfo.startDateTime?.date,
                    datetime: taskInfo.startDateTime?.datetime,
                  }}
                  onDateChange={(dateSet) => {
                    setTaskInfo((prev) => ({
                      ...prev,
                      startDateTime: {
                        timezone: dayjs.tz.guess(),
                        datetime: dateSet.datetime,
                        date: dateSet.date,
                      },
                    }));
                  }}
                />
              )}
              <TaskDueDatePicker
                label={taskInfo.dueDateTime.datetime ? 'Due Date (End)' : 'Due Date'}
                minDateTime={
                  taskInfo.startDateTime.datetime
                    ? dayjs(taskInfo.startDateTime.datetime)
                    : undefined
                }
                dateSet={{
                  date: taskInfo.dueDateTime?.date,
                  datetime: taskInfo.dueDateTime?.datetime,
                }}
                onDateChange={(dateSet) =>
                  setTaskInfo((prev) => ({
                    ...prev,
                    dueDateTime: {
                      timezone: dayjs.tz.guess(),
                      datetime: dateSet.datetime,
                      date: dateSet.date,
                    },
                  }))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TaskDetailModal;
