import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTimeSet } from '@demind-inc/core';

import './TaskDueDatePicker.scss';
import { Button } from '@mui/material';

export type DateTimeSetOnlyTime = Omit<DateTimeSet, 'timezone'>;

interface TaskDueDatePickerProps {
  label: string;
  dateSet?: DateTimeSetOnlyTime;
  maxDateTime?: Dayjs;
  minDateTime?: Dayjs;
  onDateChange: (date: DateTimeSetOnlyTime) => void;
}

export const TaskDueDatePicker: React.FC<TaskDueDatePickerProps> = ({
  label,
  dateSet,
  maxDateTime,
  minDateTime,
  onDateChange,
}) => {
  const hasDateSet = dateSet ? !!Object.values(dateSet).filter((i) => !!i).length : false;

  const [dateTimePickerVisible, setDateTimePickerVisible] = useState(hasDateSet);
  const [dateTimeDetailPickerVisible, setDateTimeDetailPickerVisible] = useState(
    !!dateSet.datetime
  ); // Flag to show a picker to set a hour and minute

  return (
    <div className="task-due-date-picker">
      {dateTimePickerVisible && dateTimeDetailPickerVisible && (
        <DateTimePicker
          label={label}
          maxDateTime={maxDateTime}
          minDateTime={minDateTime}
          value={hasDateSet ? dayjs(dateSet.datetime || dateSet.date) : undefined}
          views={['month', 'day', 'hours', 'minutes']}
          onChange={(newValue) => {
            onDateChange({
              datetime: dayjs(newValue).toISOString(),
              date: dayjs(newValue).format('YYYY-MM-DD'),
            });
          }}
          className="task-due-date-picker__date-picker"
        />
      )}
      {dateTimePickerVisible && !dateTimeDetailPickerVisible && (
        <div className="task-due-date-picker__detail-group">
          <DateTimePicker
            label={label}
            value={hasDateSet ? dayjs(dateSet.datetime || dateSet.date) : undefined}
            views={['month', 'day']}
            maxDateTime={maxDateTime}
            minDateTime={minDateTime}
            onChange={(newValue) => {
              onDateChange({
                date: dayjs(newValue).format('YYYY-MM-DD'),
              });
            }}
            className="task-due-date-picker__date-picker"
          />
          <Button
            variant="text"
            className="task-due-date-picker__add-time"
            onClick={() => setDateTimeDetailPickerVisible(true)}
          >
            + Add time
          </Button>
        </div>
      )}
      {!dateTimePickerVisible && (
        <Button
          variant="text"
          className="task-due-date-picker__add-time"
          onClick={() => {
            setDateTimePickerVisible(true);
            setDateTimeDetailPickerVisible(true);
          }}
        >
          + Add {label}
        </Button>
      )}
    </div>
  );
};
